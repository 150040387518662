import { useQuery } from '@tanstack/react-query';
import { RootObject } from '../types/alertBanner';

export const useNotificationList = () => {
    return useQuery<RootObject, Error>(['notifications'], async () => {
      const { REACT_APP_WEB_API } = process.env;
  
      const url = `${REACT_APP_WEB_API}public/getalerts`; // Ensure this URL is correct
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      });
  

      const success = response.ok;
      

      return {
          success,
          data: await response.json(),
      };
  
  
    },
    {
      refetchOnWindowFocus: false,  
      }
  );
  };