import { useMyCerts } from '../../hooks/useMyCerts';
import { handleDigitalCertificateButtonClick, NullBadgeUrl } from '../../tools/utils';
import { MyCertifications, RootObject } from '../../types/mycertifications';
import moment from 'moment';
import { Loader } from '../Loader';
import useIsMobile from '../../hooks/Helper/useIsMobile';
import { isTablet } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import ShareModal from '../Common/SocialShare';
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';

interface IMyCertificationCardProps {
    ProviderAccessControl?: string | null;
    Name:string;
    CCCOID:string

    
}

export const MyCertificationCard = ({ ProviderAccessControl,CCCOID,Name }: IMyCertificationCardProps) => {
    const { t } = useTranslation();
    const query = useMyCerts();
    const isMobile =useIsMobile();
    
    const isIpad =(window.innerWidth >= 768 && window.innerWidth <= 1366);
    // const myCertData = query.data?.data;
    const myCertData = (query.data?.data || []) as unknown as RootObject['data']['Data'];
    const handleBorder = (CertExpirationDate: string) => {
        if (CertExpirationDate !== '0001-01-01T00:00:00') {
            const expirationDate = moment(CertExpirationDate);
            const currentDate = moment();
            const sixtyDaysBefore = expirationDate.clone().subtract(60, 'days');
            const isTodayExpiryDate = currentDate.isSame(expirationDate, 'day');
            const isExpiryWithinSixtyDays = currentDate.isBefore(expirationDate) && currentDate.isSameOrAfter(sixtyDaysBefore, 'day');

            return isTodayExpiryDate || isExpiryWithinSixtyDays;
        }
        return false;
    }
   
    const generateCertificatePDF = async (
      name: string,
      certificationName: string,
      cccoid: string,
      IssueDate: string
    ) => {
      try {
        console.log('Generating certificate for:', { name, certificationName, cccoid });
    
        const formattedDate = moment(IssueDate).format('MMMM DD, YYYY');
        const templateUrl = '/final.pdf';
        const response = await fetch(templateUrl);
        const templateBytes = await response.arrayBuffer();
    
        console.log('Template loaded successfully');
    
        const pdfDoc = await PDFDocument.load(templateBytes);
        pdfDoc.registerFontkit(fontkit);
    
        // Load fonts
        const FrutigerBoldFontBytes = await fetch('./frutiger-cufonfonts/Frutiger_bold.ttf').then(res => res.arrayBuffer());
        const FrutigerBoldFont = await pdfDoc.embedFont(FrutigerBoldFontBytes);
        
        const FrutigerFontBytes = await fetch('./frutiger-cufonfonts/Frutiger.ttf').then(res => res.arrayBuffer());
        const FrutigerFont = await pdfDoc.embedFont(FrutigerFontBytes);
    
        const pristinaFontBytes = await fetch('/Castellar-font/Castellar.TTF').then(res => res.arrayBuffer());
        const pristinaFont = await pdfDoc.embedFont(pristinaFontBytes);
    
        const pages = pdfDoc.getPages();
        const firstPage = pages[0];
    
        // Get page dimensions
        const { width, height } = firstPage.getSize();
    
        // Helper function for word wrapping
        const splitTextByWordWrap = (text: string, maxCharsPerLine: number) => {
          const words = text.split(' ');
          const lines: string[] = [];
          let currentLine = '';
    
          words.forEach(word => {
            if ((currentLine + word).length <= maxCharsPerLine) {
              currentLine += (currentLine ? ' ' : '') + word;
            } else {
              lines.push(currentLine);
              currentLine = word;
            }
          });
    
          if (currentLine) lines.push(currentLine);
    
          return lines;
        };
    
        // Split text into multiple lines
        const labelLines = splitTextByWordWrap(certificationName, 26);
        const nameLines = splitTextByWordWrap(name, 16);
    
        // Rotate and position the name
        let currentY = 225;
        for (const line of nameLines) {
          firstPage.drawText(line, {
            x: currentY,  // Adjust position after rotation
            y: width / 2 + 23 , // Centered vertically
            size: 30,
            font: pristinaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(90), // Rotate text 90° clockwise
          });
          currentY += 40;
        }
    
        // Rotate and position the certification name
        let certStartY = height / 2 -15;
        for (const line of labelLines) {
          firstPage.drawText(line, {
            x: certStartY,
            y: width / 2  + 20,
            size: 30,
            font: FrutigerBoldFont,
            color: rgb(0.78, 0.48, 0.02),
            rotate: degrees(90),
          });
          certStartY += 40;
        }
    
        // Rotate and position CCCOID & Date
        firstPage.drawText(`${cccoid}`, {
          x: height / 2 + 90,
          y: 580,
          size: 16,
          font: FrutigerFont,
          color: rgb(0, 0, 0),
          rotate: degrees(90),
        });
    
        firstPage.drawText(formattedDate, {
          x: height / 2 + 90, // Manually set X position (slightly above CCCOID)
          y: 310,          // Left side near the bottom
          size: 15,
          font: FrutigerFont,
          color: rgb(0, 0, 0),
          rotate: degrees(90),
        });
    
        // Save PDF
        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(blob);
    
        // Open PDF in new tab
        const newWindow = window.open();
        if (newWindow) {
          newWindow.document.write(`
            <html>
              <head><title>CCOCertificate</title></head>
              <body>
                <embed width="100%" height="100%" src="${pdfUrl}" type="application/pdf">
              </body>
            </html>
          `);
        }
    
        console.log('PDF opened in new window');
      } catch (error) {
        console.error('Error generating PDF:', error);
      }
    };

    // const generateCertificatePDF = async (name: string, certificationName: string, cccoid: string, issueDate: string) => {
    //     try {
    //         // Use moment.js to format the date as "Month dd, yyyy"
    //         const formattedDate = moment(issueDate).format('MMMM DD, YYYY');
    
    //         // Path to the PDF template
    //         const templateUrl = '/certificate.pdf';
    //         const response = await fetch(templateUrl);
    //         const templateBytes = await response.arrayBuffer();
    
    //         const pdfDoc = await PDFDocument.load(templateBytes);
    
    //         // Embed standard fonts (Times-Italic for name and Helvetica-Bold for certification label)
    //         const timesItalicFont = await pdfDoc.embedFont(StandardFonts.HelveticaOblique); // Times-Italic doesn't exist in pdf-lib, use HelveticaOblique as substitute
    //     const helveticaBoldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
    //         const pages = pdfDoc.getPages();
    //         const firstPage = pages[0];
    
    //         // Add the name in wavy font with a limit of 18 words per line
    //         const words = name.split(' ');
    //         const lineHeight = 30; // Adjust line height as needed
    //         let y = 500; // Starting Y position
    
    //         for (let i = 0; i < words.length; i += 18) {
    //             const line = words.slice(i, i + 18).join(' ');
    //             firstPage.drawText(line, {
    //                 x: 50,
    //                 y,
    //                 size: 25,
    //                 font: timesItalicFont, // Use HelveticaOblique as Times-Italic substitute for name
    //                 color: rgb(0, 0, 0),
    //             });
    //             y -= lineHeight; // Move down for the next line
    //         }
    
    //         // Add certification name with Helvetica-Bold font
    //         firstPage.drawText(certificationName, {
    //             x: 50,
    //             y: y - lineHeight,
    //             size: 25,
    //             font: helveticaBoldFont, // Use Helvetica-Bold font for certification name
    //             color: rgb(0.8, 0.48, 0.02), // Wavy-like color
    //         });
    
    //         // Add CCCOID with Helvetica-Bold font
    //         firstPage.drawText(cccoid, {
    //             x: 50,
    //             y: y - lineHeight * 2,
    //             size: 25,
    //             font: helveticaBoldFont, // Use Helvetica-Bold font for CCCOID
    //             color: rgb(0, 0, 0),
    //         });
    
    //         // Add the formatted date (Month dd, yyyy)
    //         firstPage.drawText(formattedDate, {
    //             x: 280,
    //             y: 130,
    //             size: 16,
    //             font: helveticaBoldFont, // Use Helvetica-Bold for date (optional)
    //             color: rgb(0, 0, 0),
    //         });
    
    //         // Save and download the PDF
    //         const pdfBytes = await pdfDoc.save();
    //         const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    //         const link = document.createElement('a');
    //         link.href = URL.createObjectURL(blob);
    //         link.download = `${name}_certificate.pdf`;
    //         link.click();
    //     } catch (error) {
    //         console.error('Error generating PDF:', error);
    //     }
    // };

      
    return (
        <>
            <div className="cco_row cco_row_7">
                <div className="cco_col cco_col_6">
                    <div className="cco_text cco_text_10">
                        <div className="cco_text_inner" style={{ display: 'flex', alignItems: 'center' ,marginLeft: '10px' }}>
                            <h2><strong> {t('dashboard.myCertificationCard.MyCertifications')}</strong></h2>
                           {myCertData && myCertData.length > 0 && 
                           <ShareModal CCOID={CCCOID} Name={Name}/>
                           } 
                        </div>
                    </div>
                </div>
            </div>


            <div className="cco_row cco_row_3">
                <div className="cco_col">
                    {query.isLoading ? (
                        <Loader />
                    ) : query.error ? (
                        <p>{query.error.message}</p>
                    ) : (myCertData.length > 0 ? (
                            <div className="cco_group cco_group_myCredential">
                                {myCertData.map((certification: MyCertifications, index: number) => (
                                    <div className="cco_group_item tpCards" key={certification.Id + index}>
                                
        
                                            
                                            <div className="cco_card  cco_card_myCertification" style={{ border: handleBorder(certification.CertExpirationDate) ? '4px red solid' : '' }}>
                                                <div className="cco_card_body">
                                                    <div className="cco_card_aside">
                                                        <div className="cco_img">
                                                            <span className="cco_img_wrap">
                                                                <img
                                                                    style={{
                                                                        border:
                                                                            (certification.StatusReason === 'Approved' || certification.StatusReason === 'PendingRecert' || certification.StatusReason === 'Active') &&
                                                                                certification.Logo !== null
                                                                                ? ''
                                                                                : '1px solid rgb(0 0 0 / 5%)',
                                                                        borderRadius:
                                                                            (certification.StatusReason === 'Approved' || certification.StatusReason === 'PendingRecert' || certification.StatusReason === 'Active') &&
                                                                                certification.Logo !== null
                                                                                ? ''
                                                                                : '23px',
                                                                         
                                                                    }}
                                                                    alt={certification?.MyCCOLabel}
                                                                    src={
                                                                        (certification.StatusReason === 'Approved' || certification.StatusReason === 'PendingRecert' || certification.StatusReason === 'Active') &&
                                                                            certification.Logo !== null
                                                                            ? certification.Logo
                                                                            : NullBadgeUrl
                                                                    }
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="cco_card_main"
                                                        style={{
                                                            width: '100%',
                                                            padding: '10px',
                                                        }}
                                                    >
                                                        <div className="cco_text cco_text_myCredential">
                                                            <div className="cco_text_inner">
                                                            <h3
  style={{
    fontSize: 
      (certification?.MyCCOLabel?.split(' ').length === 4 && isMobile)
        ? '9.5px' // Decrease size for mobile if there are more than 26 words
        :(certification?.MyCCOLabel?.split(' ').length === 7 && isMobile)
        ? '7.5px'
        :(certification?.MyCCOLabel?.split(' ').length === 4 && isIpad )
        ? '9.5px' // Decrease size for mobile if there are more than 26 words
        :(certification?.MyCCOLabel?.split(' ').length === 7 && isIpad)
        ? '7.5px'
        : certification?.MyCCOLabel?.split(' ').length > 4
        ? '11.5px' // Decrease size if there are more than 26 words (not mobile)
        : '12px', // Default size
  }
 }
>
  {certification?.MyCCOLabel}

  
</h3>
                                                        <div>
                                                        {t('dashboard.myCertificationCard.Status')}:{' '}
                                                                    {/* {certification.StatusReason} */}
                                                                    {t(`dashboard.myCertificationCard.Statuses.${certification.StatusReason}`,certification.StatusReason)}
                                                                </div>
                                                                <div>
                                                                  <span>
                                                                  {t('dashboard.myCertificationCard.ExpDate')}:{' '}
                                                                    {certification?.CertExpirationDate?.split('T')[0] === '0001-01-01'
                                                                        ? 'Not Available'
                                                                        : certification?.CertExpirationDate?.split('T')[0]}
                                                             
                                                                  </span>
                                                                  {/* <span style={{float:'right'}}>
                                                                  <img width="40" height="40" src="https://img.icons8.com/ios/50/certificate--v1.png" alt="certificate--v1"/>
                                                                  </span> */}
                
   {(certification.StatusReason === 'Approved' || certification.StatusReason === 'PendingRecert' || certification.StatusReason === 'Active') &&(              
<span style={{ float: "right", border: "none", background: "transparent" ,}}>
 
    <button
      onClick={(e) => {
        e.preventDefault();
        handleDigitalCertificateButtonClick();
        generateCertificatePDF(
          Name,
          certification?.MyCCOLabel || "Certification",
          CCCOID,
          certification?.CertIssueDate
        );
      }}
      style={{
        float: "right",
        border: "none",
        background: "transparent",
        cursor: "pointer",
      }
     
      }
    >
      <img
        width="40"
        height="40"
        src="https://img.icons8.com/ios/50/certificate--v1.png"
        alt="certificate-icon"

      />
    </button>
 
</span>)}




							  
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                    

                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p>   {t('dashboard.myCertificationCard.noCertifications')}</p>
                        )
                    )}
                </div>
            </div>
        </>
    );
};
